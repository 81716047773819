<template>
  <v-container
    fluid
    style="padding: 0"
  >  
    <ProjectHeader
      :filter="filter"
      :active-item-count="totalActiveCount"
      :archived-item-count="totalArchivedCount"
      @update-filter="updateFilter"
    />
    <Loader
      v-if="loaderState"
      class="projects-loader"
    />
    <template v-else-if="getActiveProjectCount == 0 && !isFilterApplied && filter != 'archived' && !tableLoadingState">
      <div class="mt-3 mb-0 white rounded-lg mx-0 project-placeholder-height d-flex justify-center align-center">
        <ActiveEmptyState
          :image-src="require('@/assets/png/table-empty-state.png')"
          :title="$t('projects.empty_state.title')"
          :button-text="$t('createProject')"
          :button-route="{ name: 'ProjectCreateView' }"
          button-color="primary"
        >
          <template #description>
            <p class="mb-0 mt-3">
              {{ $t('projects.empty_state.lets_get_started') }}
            </p>
            <p class="ma-0">
              {{ $t('projects.empty_state.take_the_lead.part1') }}
              <span class="fw-semibold">"{{ $t('projects.empty_state.take_the_lead.part2') }}"</span>
              {{ $t('projects.empty_state.take_the_lead.part3') }}
            </p>
          </template>
        </ActiveEmptyState>
      </div>
    </template>
    <template v-else-if="getArchivedProjectCount == 0 && !isFilterApplied && filter != 'active' && !tableLoadingState">
      <div class="mt-3 mb-0 white rounded-lg mx-0 project-placeholder-height d-flex justify-center align-center">
        <ArchivedEmptyState
          :image-src="require('@/assets/png/table-empty-state.png')"
          :title="$t('projects.archived_empty_state.title')"
        >
          <template #description>
            <p class="mb-0 mt-3">
              {{ $t('projects.archived_empty_state.description.part1') }}
            </p>
            <p class="mb-0">
              {{ $t('projects.archived_empty_state.description.part2') }}
            </p>
          </template>
        </ArchivedEmptyState>
      </div>  
    </template>
    <template v-else>
      <v-card
        class="py-6 px-6 mt-3 project-height"
        rounded="lg"
        elevation="0"
        width="100%"
      >
        <template>
          <div
            class="d-flex align-center justify-space-between"
          >
            <div
              class="d-flex align-center"
            >
              <SearchComponent
                :search="search"
                class="mr-3"
                @update:search="search = $event"
              />
              <ProjectFilter
                :filtered-members="this.activeMembers"
                @applyFilters="applyFilters"
              />
            </div>
            <div
              class="d-flex align-center justify-end"
            >
              <ToggleView
                :table="table"
                class="mr-3"
                @toggle-table="toggleTable"
              />
              <SettingsMenu
                table-type="projectsView"
                :required-items="['Name', 'Test Runs', 'Users']"
              />
            </div>
          </div>
          <ProjectTable
            v-if="table"
            :filtered-headers="filteredHeaders"
            :filtered-items="filteredItems"
            :item-key="itemKey"
            :row-class="rowClass"
            @select-item="useProject"
            @edit-item="editItem"
            @archive-item="confirmArchiveProject"
            @unarchive-item="confirmUnarchiveProject"
            @delete-item="confirmDeleteProject"
            @toggle-star="handleToggleStar"
          />

          <v-row
            v-else-if="!table && filteredItems.length > 0"
            class="mt-6"
          >
            <v-col
              v-for="(item, index) in filteredItems"
              :key="index"
              cols="4"
            >
              <ProjectCard
                :item="item"
                :filter-items="filteredMenuHeaders"
                @select-item="useProject"
                @edit-item="editItem"
                @archive-item="confirmArchiveProject"
                @unarchive-item="confirmUnarchiveProject"
                @delete-item="confirmDeleteProject"
                @toggle-star="handleToggleStar"
              />
            </v-col>
          </v-row>
          <div
            v-else
            class="py-10"
          >
            {{ $t('projects.no_data_available') }}
          </div>
        </template>
      </v-card>
    </template>
    <ProjectDiscardDialog
      v-model="showConfirmDeleteDialog"
      :title="$t('projects.delete_project_dialog.title')"
      @close="closeDeleteDialog"
      @handleConfirmClick="confirmDeleteProject"
    >
      <template v-slot:content>
        <v-flex class="mt-4 flex-column">
          <p class="text-start">
            {{ $t('projects.delete_project_dialog.warning') }}
          </p>
          <p
            v-if="filter != 'archived'"
            class="text-start"
          >
            {{ $t('projects.delete_project_dialog.recommend') }}
          </p>
        </v-flex>
      </template>
      <template v-slot:footer>
        <v-row>
          <v-col cols="6">
            <v-btn
              v-if="filter != 'archived'"
              depressed
              height="40px"
              width="100%"
              class="text-capitalize btn-theme rounded-lg black--text mt-2"
              background-color="#F2F4F7"
              @click="archiveProject"
            >
              {{ $t('archive') }}
            </v-btn>
            <v-btn
              v-else
              depressed
              height="40px"
              width="100%"
              class="text-capitalize btn-theme rounded-lg black--text mt-2"
              background-color="#F2F4F7"
              @click="closeDeleteDialog"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              depressed
              height="40px"
              width="100%"
              class="text-capitalize btn-theme rounded-lg white--text mt-2"
              color="danger"
              @click="deleteProject"
            >
              {{ $t('projects.create_project.close_dialog.confirm_button') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ProjectDiscardDialog>
    <ProjectDiscardDialog
      v-model="showConfirmArchiveDialog"
      :title="$t('projects.archive_project_dialog.title', { action: $t('archive') })"
      @close="closeArchiveDialog"
      @handleConfirmClick="confirmArchiveProject"
    >
      <template v-slot:content>
        <v-flex class="mt-4">
          <p class="text-start">
            {{ $t('projects.archive_project_dialog.warning') }}
          </p>
        </v-flex>
      </template>
      <template v-slot:footer>
        <v-row>
          <v-col cols="6">
            <v-btn
              depressed
              height="40px"
              width="100%"
              class="text-capitalize btn-theme rounded-lg black--text mt-2"
              background-color="#F2F4F7"
              @click="closeArchiveDialog"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              depressed
              width="100%"
              class="text-capitalize rounded-lg btn-theme white--text mt-2"
              color="primary"
              height="40px"
              @click="archiveProject"
            >
              {{ $t('archive') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ProjectDiscardDialog>
    <ProjectDiscardDialog
      v-model="showConfirmUnarchiveDialog"
      :title="$t('projects.archive_project_dialog.title', { action: $t('unarchive') })"
      @close="closeUnarchiveDialog"
      @handleConfirmClick="confirmUnarchiveProject"
    >
      <template v-slot:content>
        <v-flex class="mt-4">
          <p class="text-start">
            {{ $t('projects.archive_project_dialog.unarchive_warning') }}
          </p>
        </v-flex>
      </template>
      <template v-slot:footer>
        <v-row>
          <v-col cols="6">
            <v-btn
              depressed
              height="40px"
              width="100%"
              class="text-capitalize btn-theme rounded-lg black--text mt-2"
              background-color="#F2F4F7"
              @click="closeUnarchiveDialog"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              depressed
              height="40px"
              width="100%"
              class="text-capitalize btn-theme rounded-lg white--text mt-2"
              color="blue"
              @click="unarchiveProject"
            >
              {{ $t('unarchive') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ProjectDiscardDialog>
  </v-container>
</template>

<script>
import ProjectHeader from '@/components/Project/ProjectHeader';
import ProjectFilter from '@/components/Project/ProjectFilter.vue';
import SearchComponent from '@/components/Project/SearchComponent.vue';
import ProjectCard from '@/components/Project/ProjectCard.vue';
import ToggleView from '@/components/Project/ToggleView.vue';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import ProjectTable from '@/components/Project/ProjectTable.vue';
import ProjectDiscardDialog from '@/components/Project/ProjectDiscardDialog';
import { createNamespacedHelpers, mapGetters, mapActions } from 'vuex';
import ProjectsService from '@/services/api/project';
import { handleNetworkStatusError } from '@/mixins/redirect';
import Loader from '@/components/base/Loader';
import makeOrgService from '@/services/api/org';
import ActiveEmptyState from '@/components/base/ActiveEmptyState.vue';
import ArchivedEmptyState from '@/components/base/ArchivedEmptyState.vue';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import { sleep } from '@/utils/util';
import { items } from '@/constants/data.js';

const { mapState: mapUserState } = createNamespacedHelpers('user');

let makeProjectService

export default {
  name: 'Project',
  components: {
    ProjectHeader,
    ProjectFilter,
    SearchComponent,
    ProjectCard,
    ProjectTable,
    ToggleView,
    SettingsMenu,
    ProjectDiscardDialog,
    Loader,
    ActiveEmptyState,
    ArchivedEmptyState
  },
  mixins: [handleNetworkStatusError],
  data() {
    return {
      handle: this.$route.params.handle,
      selectedProject: {},
      projects: [],
      totalArchived: 0,
      totalActive: 0,
      loaderState: false,
      loading: false,
      filter: 'active',
      isFilterApplied: false,
      search: '',
      items: items,
      table: true,
      options: ['active', 'archived'],
      membersState: {
        isLoading: false,
        hasError: false,
        errorMessage: '',
      },
      activeMembers: [],
      headers: [],
      itemKey: 'uid',
      rowClass: () => 'project-item',
      showConfirmDeleteDialog: false,
      showConfirmArchiveDialog: false,
      showConfirmUnarchiveDialog: false,
      tableLoadingState: false,
    };
  },
  computed: {
    ...mapGetters({
      dynamicHeaders: 'headers/dynamicHeaders'
    }),
    ...mapUserState(['currentAccount']),
    filteredHeaders() {
      const filtered = this.filteredMenuHeaders.filter((header) => header.checked);
      return filtered;
    },
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.text != 'Actions');
      return filtered;
    },
    filteredItems() {
      let filtered = this.projects;
      if (this.search) {
        const searchTerm = this.search.toLowerCase();
        filtered = filtered.filter((project) => project.name.toLowerCase().includes(searchTerm));
      }
      return filtered;
    },

    totalActiveCount() {
      return this.filter === 'active' ? this.filteredItems.length : this.totalActive;
    },
    totalArchivedCount() {
      return this.filter === 'archived' ? this.filteredItems.length : this.totalArchived;
    },
    getActiveProjects() {
      return this.projects.filter((project) => !project.archivedAt);
    },
    getActiveProjectCount() {
      return this.getActiveProjects.length;
    },
    getArchivedProjects() {
      return this.projects.filter((project) => project.archivedAt);
    },
    getArchivedProjectCount() {
      return this.getArchivedProjects.length;
    },

  },
  mounted() {
    this.init();
  },
  created() {
    if(!this.dynamicHeaders.projectsView) {
      this.initializeHeaders({ type: 'projectsView' });
    }
    this.headers = this.dynamicHeaders.projectsView;
    makeProjectService = ProjectsService(this.$api);
  },
  methods: {
    ...mapActions("headers", ['initializeHeaders']),
    useProject(project) {
      this.$router.push({
        name: 'Cases',
        params: {
          handle: this.$route.params.handle,
          key: project.key
        },
      });
    },
    async getProjects(additionalParams = {}, handle) {
      if (!handle) handle = this.handle;
      const searchParams = new URLSearchParams();
      searchParams.set('status', this.filter);
      searchParams.set('includeCount', true);
    
      Object.keys(additionalParams).forEach(key => {
        searchParams.set(key, additionalParams[key]);
      });

      this.loading = true;
      try {
        const response = await makeProjectService.getProjects(handle, searchParams.toString());
        this.projects = response.data.projects;
        this.totalActive = response.data.meta.totalActive;
        this.totalArchived = response.data.meta.totalArchived;
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'fetchError', { item: 'projects' });
      } finally {
        this.loading = false;
      }
    },

    async applyFilters(queryParams,filterApplied) {
      this.isFilterApplied = filterApplied;
      try {
        await this.getProjects(queryParams);
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'projects' });
      } 

    },

    async init() {
      this.loaderState = false;
      this.tableLoadingState = true;
      let loaderStateTimeout = null;

      loaderStateTimeout = setTimeout(() => {
        this.loaderState = true;
      }, 1000);

      try {
        await this.getProjects(); 
        this.getMembers(this.handle);
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'projects' });
      } finally {
        clearTimeout(loaderStateTimeout); 
        const hideLoader = async () => {
          await sleep(1000); 
          this.loaderState = false; 
          this.tableLoadingState = false;
        };
        hideLoader(); 
      }
    },

    async getMembers(handle) {
      const orgService = makeOrgService(this.$api);
      this.membersState.isLoading = true;
      const per_page = 30;
      const next_page = 0;
      try {
        const response = await orgService.getUsers(handle, { per_page: per_page, next_page: next_page });
        this.activeMembers = response.data.users;
        this.activeUserCount = this.getActiveUserCount;
        this.membersState.isLoading = false;
      } catch (error) {
        this.membersState.isLoading = false;
        this.membersState.hasError = true;
        this.membersState.errorMessage = error.message;
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'fetchError', { item: 'members' });
      }
    },
    
    async updateFilter(newFilter) {
      this.filter = newFilter;
      this.tableLoadingState = true;
       try {
        await this.getProjects();
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'projects' });
      } finally {
        this.tableLoadingState = false;
      }
       
    },
    toggleTable(table) {
      this.table = table;
    },
    closeDeleteDialog() {
      this.showConfirmDeleteDialog = false;
      this.selectedProject = null;
    },
    confirmDeleteProject(project) {
      this.showConfirmDeleteDialog = true;
      this.selectedProject = project;
    },
    async deleteProject() {
      try {
        await makeProjectService.deleteProject(this.handle, this.selectedProject.key);
        showSuccessToast(this.$swal, 'deleteSuccess', { item: 'projects' })
        this.getProjects();
      } catch (error) {
        showErrorToast(this.$swal, 'deleteError', { item: 'projects' });
      } finally {
        this.showConfirmDeleteDialog = false;
        this.closeDeleteDialog();
      }
    },

    async archiveProject() {
      const payload = {
        customFields: {
          ...this.selectedProject.customFields,
          status: 'archive'
        },
        archived: true,
      };
      try {
        await makeProjectService.updateProject(this.handle, this.selectedProject.key, payload);
        showSuccessToast(this.$swal, 'archiveSuccess', { item: 'Project' });
        this.getProjects();
      } catch (error) {
        showErrorToast(this.$swal, 'archiveError', { item: 'Project' });
      } finally {
        this.showConfirmArchiveDialog = false;
      }
    },

    async unarchiveProject() {
      const payload = {
        customFields: {
          ...this.selectedProject.customFields,
          status: 'active'
        },
        archived: false,
      };
      try {
        await makeProjectService.updateProject(this.handle, this.selectedProject.key, payload);
        showSuccessToast(this.$swal, 'unarchiveSuccess', { item: 'Project' });
        this.getProjects();
      } catch (error) {
        showErrorToast(this.$swal, 'unarchiveError', { item: 'Project' });
      } finally {
        this.showConfirmUnarchiveDialog = false;
      }
    },
    closeArchiveDialog() {
      this.showConfirmArchiveDialog = false;
      this.selectedProject = null;
    },
    confirmArchiveProject(project) {
      this.showConfirmArchiveDialog = true;
      this.selectedProject = project;
    },
    closeUnarchiveDialog() {
      this.showConfirmUnarchiveDialog = false;
      this.selectedProject = null;
    },
    confirmUnarchiveProject(project) {
      this.showConfirmUnarchiveDialog = true;
      this.selectedProject = project;
    },
    editItem(project) {
      this.selectedProject = project;
      this.$router.push({
        name: 'ProjectDetailView',
        params: {
          handle: this.$route.params.handle,
          key: project.key,
        },
      });
    },
    async handleToggleStar(project) {
      this.selectedProject = project;
      const payload = {
        customFields: {
          ...this.selectedProject.customFields,
          star: !this.selectedProject.customFields.star,
        },
      };

      try {
        await makeProjectService.updateProject(this.$route.params.handle, this.selectedProject.key, payload);
        this.getProjects();
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'projects' });
      } 
    },
    handleRouteChange(to, from, next) {
      this.getProjects({},to.params.handle);
      next();
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.handleRouteChange(to, from, next);
  },
};
</script>
<style scoped>
.projects-loader {
  min-height: 500px;
}
.placeholder-img {
  max-width: 438px;
  width: 100%;
}
.project-height {
  min-height: calc(100vh - 24px - 6.25rem);
  height: 100%;
}
</style>
